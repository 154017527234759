import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { post } from './http';

export const createLead = createAsyncThunk('/lead/user/new', async ({ state, dispatch, reset, setModal, navigate }) => {
  try {
    const URL = `/lead/user/new`;
    const response = await post(URL, state);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Submitted successfully' }));
      if (setModal) {
        setModal(true);
      }
      if (navigate) {
        navigate('/success-page', { state: { state } });
      }
      if (reset) {
        reset();
      }
      return response;
    }
    return response;
    // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
