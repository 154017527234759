import React from 'react';
import { Button, Box, TextField, Grid, Modal } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDevice } from '../hooks/useResponsive';
import { createLead } from '../server/api/lead';
import SubmitModalLead from './popup-lead';

Lead.propTypes = {
  open: PropTypes.any,
  close: PropTypes.any,
  setOpenLeadModal: PropTypes.any,
};

export default function Lead({ open, close }) {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = React.useState(false);

  const { vehicleDetails } = useSelector((state) => ({
    vehicleDetails: state.vehicle.vehicleDetails,
  }));

  // const [selectedCurtain, setSelectedCurtain] = useState(curtainTypesData[0]);
  // const handleCurtainTypeChange = (item) => {
  //   setSelectedCurtain(item);
  // };

  // useEffect(() => {
  //   if (locationData) {
  //     const currentItem = curtainTypesData?.filter((i) => i.category === locationData);
  //     setSelectedCurtain(currentItem[0]);
  //     window.scrollTo({
  //       top: 400,
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     setSelectedCurtain(curtainTypesData[0]);
  //   }
  // }, [locationData]);
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const CustomButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '23px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const CustomButtonWeb = styled(Button)({
    width: '25%',
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '40px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '20px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      pincode: '',
      message: '',
    },
  });

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        name: value?.name,
        phone: value?.phone,
        // address: {
        //   pincode: parseInt(value?.pincode, 10),
        // },
        medium: 'website',
        message: value?.message,
        vehicle: params?.id,
        vehicleDetails,
      },
      dispatch,
      reset,
      navigate,
    };
    dispatch(createLead(credentials));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: '#1F1F1F',
    boxShadow: 'none',
    borderRadius: '10px',
    height: 'auto',
    textAlign: 'center',
    padding: 2,
  };

  // const handleModal = () => {
  //   // setModal(true);
  //   setOpenLeadModal(false);
  // };

  return (
    <>
      <Modal open={open} onClose={close} aria-labelledby="modal-modal" aria-describedby="modal-modal">
        <Box sx={style}>
          <SubmitModalLead open={modal} close={() => setModal(false)} />
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Box mt={3} px={0}>
              <Grid container>
                <Grid item md={isMobile ? ' ' : 4} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="Enter your name *"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    {...register('name', {
                      required: { value: true, message: 'name is required' },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? ' ' : 4} px={isMobile ? '' : 1} xs={12}>
                  <TextField
                    fullWidth
                    name="phone"
                    placeholder="Enter your phone*"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('phone', {
                      required: { value: true, message: '' },
                      maxLength: {
                        value: 15,
                        message: 'Phone must not exceed 15 characters',
                      },
                      minLength: {
                        value: 9,
                        message: 'Phone must have at least 9 characters',
                      },
                    })}
                  />
                </Grid>

                <Grid md={isMobile ? ' ' : 12} xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    placeholder="Note (if any)"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    rows={3}
                    multiline
                    {...register('message', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
              </Grid>
              {isMobile ? (
                <CustomButton
                  type="submit"
                  variant="contained"
                  // onClick={() => handleModal()}
                  className="request-btn"
                  fullWidth
                >
                  Submit
                </CustomButton>
              ) : (
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                  <CustomButtonWeb
                    type="submit"
                    variant="contained"
                    className=""
                    // onClick={() => setOpenLeadModal(false)}
                  >
                    Submit
                  </CustomButtonWeb>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
}
