import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useDevice } from '../../../hooks/useResponsive';
import ProductCard from '../../Products/productCard';

// const categoryData = [
//   {
//     name: 'Mahindra THar',
//     variant: ' LX 4',
//     fuel: 'Diesel',
//     image: '/assets/images/Categories/redThar.png',
//     year: '2023',
//   },
//   {
//     name: 'Mahindra THar',
//     variant: ' LX 4',
//     fuel: 'Petrol',
//     image: '/assets/images/Categories/blackThar.png',
//     year: '2022',
//   },
//   {
//     name: 'Mahindra THar',
//     variant: ' LX 4',
//     fuel: 'Diesel',
//     image: '/assets/images/Categories/redThar.png',
//     year: '2023',
//   },
//   {
//     name: 'Mahindra THar',
//     variant: ' LX 4',
//     fuel: 'Petrol',
//     image: '/assets/images/Categories/blackThar.png',
//     year: '2022',
//   },
// ];

export default function VehiclesByCategory({ vehicles }) {
  const { isMobile } = useDevice();

  const filteredVehicles = vehicles?.filter((vehicle) => vehicle.isSold === false);

  return (
    <Grid container direction="row" mt={2} spacing={2}>
      {(isMobile ? filteredVehicles : filteredVehicles?.slice(0, 8))?.map((vehicle, index) => (
        <Grid item xs={6} md={3} lg={3} mb={3} key={vehicle.id || index}>
          <ProductCard product={vehicle} />
          <div className="prod-div" />
        </Grid>
      ))}
    </Grid>
  );
}

VehiclesByCategory.propTypes = {
  vehicles: PropTypes.any,
};
