/* eslint-disable no-unused-expressions */
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDevice } from '../hooks/useResponsive';
import img from '../images/success-page.png';

// Assuming fbq is a global variable provided by Facebook Pixel script
// Assuming fbq is a global variable provided by Facebook Pixel script
const { fbq } = window;

function SuccessPage() {
  const location = useLocation();
  const { state } = location;
  const { isMobile } = useDevice();

  console.log(state);

  const userDetails = state?.state;
  const vehicleDetails = state?.state?.vehicleDetails;

  // in success page

  useEffect(() => {
    const products = [
      {
        id: vehicleDetails?._id,
        quantity: 1,
        model_name: vehicleDetails?.model?.name,
        model_id: vehicleDetails?.model?._id,
        brand: vehicleDetails?.model?.brand?.name,
        brand_id: vehicleDetails?.model?.brand?._id,
        variant: vehicleDetails?.variant?.name,
        variant_id: vehicleDetails?.variant?._id,
        fuelType: vehicleDetails?.fuelType,
        makeYear: vehicleDetails?.makeYear,
        ownershipStatus: vehicleDetails?.ownershipStatus,
        vehicleNumber: vehicleDetails?.vehicleNumber,
      },
    ];

    if (typeof fbq === 'function') {
      typeof fbq('track', 'AddToCart', {
        value: 0,
        currency: 'INR',
        contents: products,
        content_type: 'product',
        user_name: userDetails?.name || '',
        phone_number: userDetails?.phone || '',
      });

      console.log('Facebook Pixel Purchase event triggered.');
    } else {
      console.warn('Facebook Pixel (fbq) is not available.');
    }
  }, [userDetails, vehicleDetails]);

  return (
    <Stack sx={{ backgroundColor: '#121212', borderColor: 'none' }} spacing={2}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        py={2}
        sx={{ textAlign: 'center' }}
        minHeight={500}
      >
        <img
          src={img}
          alt="success"
          style={{
            height: isMobile ? 80 : '120px',
            width: isMobile ? 80 : '120px',
          }}
        />
        <Stack pt={2}>
          <Typography variant={isMobile ? 'h5' : 'h3'} color="GrayText">
            Submitted Successfully!
          </Typography>
          <Typography color="gray" variant={isMobile ? 'subtitle2' : 'subtitle1'}>
            Your application submitted successfully
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SuccessPage;
