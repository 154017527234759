import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Stack,
  Chip,
  Grid,
  Slider,
  Divider,
  Typography,
  TextField,
  Checkbox,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
// import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
// import { CheckBox } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import ProductCard from '../segment/Products/productCard';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import ProductCard2 from '../segment/Products/productCard2';
import Youtube from '../segment/@common/Youtube/youtube';
import { FilterDrawer } from '../components/drawer/filterDrawer';
import { getAllBrands, getAllModels, getFilteredVehicles } from '../server/api/vehicle';
import { getAllCategories } from '../server/api/category';
import { useDevice } from '../hooks/useResponsive';

export default function AllProducts() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brandId = searchParams.get('id');

  const { brands, models, vehicleList, categoryList, loading } = useSelector((state) => ({
    brands: state.vehicle.brands?.response,
    models: state.vehicle.models?.response,
    vehicleList: state.vehicle.vehicleList,
    loading: state.vehicle.loading,
    categoryList: state.category.categoryList?.response,
  }));

  const totalVehicles = vehicleList?.total;
  const vehicles = vehicleList?.vehicles;

  const [multipleBrands, setMultipleBrands] = React.useState([]);
  const [multipleModels, setMultipleModels] = React.useState([]);
  const [multipleYears, setMultipleYears] = React.useState([]);
  const [multipleFuels, setMultipleFuels] = React.useState([]);
  const [kilometersDriven, setKilometersDriven] = React.useState([50000, 150000]);
  const [searchBrand, setSearchBrand] = React.useState('');
  const [customLoading, setCustomLoading] = useState(false);
  const [allVehicles, setAllVehicles] = useState([]);
  const limitValue = 10;
  const allpages = vehicleList?.total / limitValue;
  const [pages, setPages] = useState(1);
  console.log(multipleFuels);
  useEffect(() => {
    const updateAllVehicles = () => {
      setAllVehicles((prevAllVehicles) => {
        if (pages === 1) {
          return [...(vehicles || [])];
        }
        return [...prevAllVehicles, ...(vehicles || [])];
      });
    };

    updateAllVehicles();
  }, [pages, vehicles]);

  const ids = allVehicles?.map((o) => o._id);

  const filteredFinalProd = allVehicles?.filter(({ _id }, index) => !ids.includes(_id, index + 1));

  useEffect(() => {
    if (loading === true) {
      setCustomLoading(true);
    }
    if (loading === false) {
      setTimeout(() => {
        setCustomLoading(false);
      }, 1500);
    }
  }, [loading]);

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1);
    }
  };
  // const today = moment().format('YYYY-MM-DD');
  const allProductsFilterSoldDate = () => {
    const allProducts = filteredFinalProd?.filter(
      (item) =>
        // Remove the item if it is sold
        !item?.isSold
    );

    return allProducts;
  };

  // const brandId = location?.search?.split('?brand=')[1];

  const [category, setCategory] = useState('');

  const handleChipClick = (id) => {
    setCategory(id);
  };

  useEffect(() => {
    if (brandId) {
      setMultipleBrands((prevItems) => [...prevItems, brandId]);
    }
  }, [brandId]);

  const filterData = {
    multipleBrands,
    setMultipleBrands,
    multipleModels,
    setMultipleModels,
    multipleYears,
    setMultipleYears,
    multipleFuels,
    setMultipleFuels,
    kilometersDriven,
    setKilometersDriven,
    brands,
    models,
    totalVehicles,
    setSearchBrand,
  };

  useEffect(() => {
    const credentials = {
      dispatch,
      page: 1,
      limit: 30,
      searchText: searchBrand,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch, searchBrand]);

  useEffect(() => {
    const credentials = {
      dispatch,
      page: 1,
      limit: 10,
      brand: '',
    };
    dispatch(getAllModels(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: '1',
      search: '',
      limit: 30,
      dispatch,
    };
    dispatch(getAllCategories(credentials));
  }, [dispatch]);

  useEffect(() => {
    // Reset pages to 1 when any filter changes
    setPages(1);
  }, [multipleBrands, multipleModels, multipleYears, multipleFuels, kilometersDriven, category]);

  useEffect(() => {
    const credentials = {
      state: {
        filter: {
          brand: multipleBrands,
          model: multipleModels,
          makeYear: multipleYears,
          fuelType: multipleFuels,
          kiloMeter: { from: kilometersDriven[0], to: kilometersDriven[1] },
        },
      },
      catId: category,
      page: pages,
      limit: limitValue,
    };
    dispatch(getFilteredVehicles(credentials));
  }, [category, dispatch, kilometersDriven, multipleBrands, multipleFuels, multipleModels, multipleYears, pages]);

  const [state, setState] = React.useState({
    left: false,
  });

  const [brandCheck, setBrandCheck] = React.useState([]);
  const [yearCheck, setYearCheck] = React.useState([]);
  // console.log(yearCheck)
  const handleCheckboxChangeYear = (event, setMultipleSelect, yearValue, setYearCheck, yearRangeValue) => {
    const isChecked = event.target.checked;
    const filterItem = yearValue?.map((item) => item);
    if (isChecked) {
      setMultipleSelect((prevItems) => [...prevItems, ...filterItem]);
      setYearCheck((prevItems) => [...prevItems, yearRangeValue]);
    } else {
      setMultipleSelect((prevItems) => prevItems.filter((item) => !filterItem.includes(item)));
      setYearCheck((prevItems) => prevItems.filter((item) => item !== yearRangeValue));
    }
  };

  const handleCheckboxChange = (event, setMultipleSelect) => {
    const isChecked = event.target.checked;
    const filterItem = event.target.value;
    if (isChecked) {
      setMultipleSelect((prevItems) => [...prevItems, filterItem]);
    } else {
      setMultipleSelect((prevItems) => prevItems.filter((item) => item !== filterItem));
    }
  };

  const handleCheckBrand = (event, value) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setBrandCheck((prevItems) => [...prevItems, value]);
    } else {
      setBrandCheck((prevItems) => prevItems.filter((item) => item !== value));
    }
  };

  const [toggleBrand, setToggleBrand] = React.useState({});
  const [toggleYear, setToggleYear] = React.useState({});
  const [filterItem, setFilterItem] = React.useState('');

  console.log(toggleBrand);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    setFilterItem('BRAND');
  }, []);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const filterItems = ['BRAND', 'YEAR', 'FUEL', 'KMs'];

  const years = [
    { name: '2021 & ABOVE', models: ['2021'] },
    { name: '2016-2020', models: ['2020', '2019', '2018', '2017', '2016'] },
    { name: '2011-2015', models: ['2015', '2014', '2013', '2012', '2011'] },
    { name: '2006-2010', models: ['2010', '2009', '2008', '2007', '2006'] },
    { name: '2001-2005', models: ['2005', '2004', '2003', '2002', '2001'] },
    { name: '1990-2000', models: ['2015', '2014', '2013', '2012', '2011'] },
  ];

  // const years = [
  //   '2024',
  //   '2023',
  //   '2022',
  //   '2021',
  //   '2020',
  //   '2019',
  //   '2018',
  //   '2017',
  //   '2016',
  //   '2015',
  //   '2014',
  //   '2013',
  //   '2012',
  //   '2011',
  //   '2010',
  //   '2009',
  //   '2008',
  //   '2007',
  //   '2006',
  //   '2005',
  //   '2004',
  //   '2003',
  //   '2002',
  //   '2001',
  //   '2000',
  //   '1999',
  //   '1998',
  //   '1997',
  //   '1996',
  // ];

  const fuels = [
    { label: 'PETROL', value: 'petrol' },
    { label: 'DIESEL', value: 'diesel' },
    { label: 'CNG', value: 'cng' },
    { label: 'LPG', value: 'lpg' },
    { label: 'ELECTRIC', value: 'electric' },
  ];

  const handleChange = (event, newValue) => {
    setKilometersDriven(newValue);
  };

  const handleFilterCount = (item) => {
    switch (item) {
      case 'BRAND':
        return multipleBrands?.length;
      case 'YEAR':
        return multipleYears?.length;
      case 'FUEL':
        return multipleFuels?.length;
      default:
        return 0;
    }
  };

  const clearAllFilterItems = () => {
    setMultipleBrands([]);
    setMultipleModels([]);
    setMultipleYears([]);
    setMultipleFuels([]);
    setKilometersDriven([50000, 150000]);
  };

  const handleModels = (brandId) => {
    const filterModels = models?.models?.filter((item) => item?.brand?._id === brandId && item);
    return filterModels;
  };

  const ShowButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: 'smaller',
    fontWeight: '700',
    width: '50%',
    marginRight: '10px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });
  const ClearButton = styled(Button)({
    background: '#121212',
    color: '#727272',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: 'smaller',
    fontWeight: '700',
    width: '50%',
    marginRight: '10px',
    marginLeft: '10px',
    borderColor: '#121212',
    '&:hover': {
      background: '#121212',
      color: '#727272',
      borderColor: '#121212',
    },
  });

  return (
    <>
      <MetaHelmet title={'All Products'} companyName={'Focus Motors'} />
      <Box className="bg-color">
        {isMobile ? (
          <Container maxWidth="lg" sx={{ padding: '0px 5px 0px 10px' }}>
            <Stack direction="row" spacing={1} mb={2}>
              <FilterDrawer filterData={filterData} />
              <Grid display={'flex'} className="cat-scroller">
                <Chip
                  label="All Vehicles"
                  onClick={() => handleChipClick('')}
                  sx={{
                    backgroundColor: category === '' ? '#684500' : '#202020',
                    color: '#E3E3E3',
                    fontWeight: '400px',
                    marginRight: '5px',
                  }}
                />
                {categoryList?.map((item, key) => (
                  <Chip
                    label={item?.name}
                    key={key}
                    onClick={() => handleChipClick(item?._id)}
                    sx={{
                      backgroundColor: category === item?._id ? '#684500' : '#202020',
                      color: '#E3E3E3',
                      fontWeight: '400px',
                      marginRight: '5px',
                    }}
                  />
                ))}
              </Grid>
            </Stack>
            <InfiniteScroll
              dataLength={allProductsFilterSoldDate()?.length ?? 0}
              next={HandlefetchNextPage}
              hasMore
              loader={customLoading}
              scrollThreshold={0.6}
              className="infinite-scroll"
            >
              {allProductsFilterSoldDate()?.map((vehicle, key) => (
                <ProductCard2 vehicle={vehicle} key={key} />
              ))}
            </InfiniteScroll>
            <Youtube />
          </Container>
        ) : (
          <>
            <Grid container sx={{ position: '' }}>
              <Grid item md={1}>
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: '#121212',
                    // minHeight: '100vh',
                    marginTop: -3,
                  }}
                  role="presentation"
                  // onClick={toggleDrawer(anchor, false)}
                  // onKeyDown={toggleDrawer(anchor, false)}
                >
                  {/* <Box
                    sx={{ backgroundColor: '#202020', width: '100%', height: '70px' }}
                    display={'flex'}
                    alignItems={'center'}
                    px={4}
                    mt={1}
                  >
                    {' '}
                    <CloseIcon style={{ color: '#FFA800' }} fontSize="large" onClick={toggleDrawer('left', false)} />
                    <Typography color={'#D0D0D0'} fontSize={22} fontWeight={400} pl={1} pt={1}>
                      FILTERS
                    </Typography>
                  </Box> */}
                  <Grid display={'flex'} mt={3}>
                    <Box
                      sx={{
                        backgroundColor: '#202020',
                        width: '100%',
                        display: 'flex',
                        height: '85vh',
                        justifyContent: 'center',
                      }}
                    >
                      {' '}
                      <Stack mt={2}>
                        {filterItems?.map((item, key) => (
                          <Typography
                            key={key}
                            color={item === filterItem ? '#FFA800' : '#D0D0D0'}
                            pb={1}
                            onClick={() => setFilterItem(item)}
                            position={'relative'}
                            sx={{ cursor: 'pointer' }}
                          >
                            {item !== 'KMs' && handleFilterCount(item) >= 1 && (
                              <Box
                                borderRadius={'50%'}
                                sx={{
                                  backgroundColor: '#FFA800',
                                  width: '16px',
                                  height: '16px',
                                  position: 'absolute',
                                  right: item === 'BRAND' ? -7 : item === 'KMs' ? 7 : 0,
                                  top: item === 'BRAND' ? -7 : item === 'KMs' ? -2 : -6,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography color={'#121212'} fontSize={10} fontWeight={700}>
                                  {handleFilterCount(item)}
                                </Typography>
                              </Box>
                            )}
                            {item}
                          </Typography>
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                  {isMobile && (
                    <Box
                      sx={{ backgroundColor: '#202020', width: '100%', height: '70px' }}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      position={'fixed'}
                      bottom={0}
                    >
                      {' '}
                      <ClearButton type="submit" variant="outlined" onClick={clearAllFilterItems}>
                        Clear All
                      </ClearButton>
                      <ShowButton type="submit" variant="contained" onClick={toggleDrawer('left', false)}>
                        {`SHOW ${totalVehicles} VEHICLES`}
                      </ShowButton>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item md={3}>
                {filterItem === 'BRAND' ? (
                  <Box width={'100%'}>
                    <Container>
                      <Stack position={'relative'}>
                        <TextField
                          fullWidth
                          placeholder="Search brand"
                          id="fullWidth"
                          type="text"
                          className="filter-search"
                          onChange={(e) => setSearchBrand(e.target.value)}
                        />
                        <img
                          src="assets/images/Home/search.png"
                          alt=""
                          style={{ width: '15px', height: '15px', position: 'absolute', left: 14, top: 14 }}
                        />
                      </Stack>
                      <SimpleBar style={{ maxHeight: '750px', padding: 0 }}>
                        {brands?.brands?.map((brand, brandIndex) => (
                          <Stack key={brandIndex}>
                            <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                              <Box display={'flex'} alignItems={'center'} position={'relative'}>
                                {brandCheck?.includes(brand?._id) &&
                                  multipleBrands?.length === 0 &&
                                  multipleModels?.length > 0 && (
                                    <Box
                                      sx={{
                                        backgroundColor: '#FFA800',
                                        width: '8px',
                                        height: '8px',
                                        position: 'absolute',
                                        left: 17,
                                      }}
                                    />
                                  )}
                                <Checkbox
                                  {...label}
                                  className="filter-check"
                                  style={{ color: '#FFA800' }}
                                  value={brand?._id}
                                  onChange={(e) => handleCheckboxChange(e, setMultipleBrands)}
                                  checked={multipleBrands?.includes(brand?._id)}
                                />
                                <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                  {brand?.name}
                                </Typography>
                              </Box>
                              {toggleBrand[brandIndex] && models?.models?.filter((item) => item?.brand) ? (
                                <KeyboardArrowUpIcon
                                  onClick={() => setToggleBrand({ ...toggleBrand, [brandIndex]: false })}
                                  style={{ color: '#FFA800', marginRight: '10px' }}
                                  fontSize="medium"
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() => setToggleBrand({ ...toggleBrand, [brandIndex]: true })}
                                  style={{ color: '#FFA800', marginRight: '10px' }}
                                  fontSize="medium"
                                />
                              )}
                            </Box>
                            {toggleBrand[brandIndex] && (
                              <>
                                {handleModels(brand?._id)?.map((model, modelIndex) => (
                                  <Box key={modelIndex} display={'flex'} alignItems={'center'} pl={3}>
                                    <Checkbox
                                      {...label}
                                      className="filter-check"
                                      style={{ color: '#FFA800' }}
                                      value={model?._id}
                                      onChange={(e) => {
                                        handleCheckboxChange(e, setMultipleModels);
                                        handleCheckBrand(e, model?.brand?._id);
                                      }}
                                      checked={multipleModels?.includes(model?._id)}
                                    />
                                    <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                      {model?.name}
                                    </Typography>
                                  </Box>
                                ))}
                              </>
                            )}
                            <Divider />
                          </Stack>
                        ))}
                      </SimpleBar>
                    </Container>
                  </Box>
                ) : filterItem === 'YEAR' ? (
                  <Box width={'100%'}>
                    <Container maxWidth="md">
                      <SimpleBar style={{ maxHeight: '750px' }}>
                        {years?.map((year, yearIndex) => (
                          <Stack key={yearIndex}>
                            <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                              {/* <Box display={'flex'} alignItems={'center'}>
                                <Checkbox
                                  {...label}
                                  className="filter-check"
                                  style={{ color: '#FFA800' }}
                                  value={year}
                                  onChange={(e) => handleCheckboxChange(e, setMultipleYears, year?.models)}
                                  checked={multipleYears?.includes(year)}
                                />
                                <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                  {year}
                                </Typography>
                              </Box> */}
                              <Box display={'flex'} alignItems={'center'}>
                                <Checkbox
                                  {...label}
                                  className="filter-check"
                                  style={{ color: '#FFA800' }}
                                  value={year?.name}
                                  onChange={(e) =>
                                    handleCheckboxChangeYear(
                                      e,
                                      setMultipleYears,
                                      year?.models,
                                      setYearCheck,
                                      year?.name
                                    )
                                  }
                                  checked={yearCheck?.includes(year?.name)}
                                />
                                <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                  {year?.name}
                                </Typography>
                              </Box>
                              {toggleYear[yearIndex] ? (
                                <KeyboardArrowUpIcon
                                  onClick={() => setToggleYear({ ...toggleYear, [yearIndex]: false })}
                                  style={{ color: '#FFA800', marginRight: '10px' }}
                                  fontSize="medium"
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() => setToggleYear({ ...toggleYear, [yearIndex]: true })}
                                  style={{ color: '#FFA800', marginRight: '10px' }}
                                  fontSize="medium"
                                />
                              )}
                            </Box>
                            {toggleYear[yearIndex] && (
                              <>
                                {year?.models?.map((model, modelIndex) => (
                                  <Box key={modelIndex} display={'flex'} alignItems={'center'} pl={3}>
                                    <Checkbox
                                      {...label}
                                      className="filter-check"
                                      style={{ color: '#FFA800' }}
                                      value={model}
                                      onChange={(e) => handleCheckboxChange(e, setMultipleYears)}
                                      checked={multipleYears?.includes(model)}
                                    />
                                    <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                      {model}
                                    </Typography>
                                  </Box>
                                ))}
                              </>
                            )}
                            <Divider />
                          </Stack>
                        ))}
                      </SimpleBar>
                    </Container>
                  </Box>
                ) : filterItem === 'FUEL' ? (
                  // <Box width={'100%'}>
                  //   <Container maxWidth="md">
                  //     <SimpleBar style={{ maxHeight: '460px' }}>
                  //       {fuels?.map((fuel, fuelIndex) => (
                  //         <Stack key={fuelIndex}>
                  //           <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  //             <Box display={'flex'} alignItems={'center'}>
                  //               <CheckBox
                  //                 {...label}
                  //                 className="filter-check"
                  //                 style={{ color: '#FFA800' }}
                  //                 value={fuel?.value}
                  //                 onChange={(e) => handleCheckboxChange(e, setMultipleFuels)}
                  //                 checked={multipleFuels?.includes(fuel?.value)}
                  //               />
                  //               <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                  //                 {fuel?.label}
                  //               </Typography>
                  //             </Box>
                  //           </Box>
                  //           <Divider />
                  //         </Stack>
                  //       ))}
                  //     </SimpleBar>
                  //   </Container>
                  // </Box>
                  <Box width={'100%'}>
                    <Container maxWidth="md">
                      <SimpleBar style={{ maxHeight: '750px' }}>
                        {fuels?.map((fuel, fuelIndex) => (
                          <Stack key={fuelIndex}>
                            <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                              <Box display={'flex'} alignItems={'center'}>
                                <Checkbox
                                  {...label}
                                  className="filter-check"
                                  style={{ color: '#FFA800' }}
                                  value={fuel?.value}
                                  onChange={(e) => handleCheckboxChange(e, setMultipleFuels)}
                                  checked={multipleFuels?.includes(fuel?.value)}
                                />
                                <Typography color={'#D0D0D0'} fontWeight={400} fontSize={18}>
                                  {fuel?.label}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </Stack>
                        ))}
                      </SimpleBar>
                    </Container>
                  </Box>
                ) : (
                  <Box width={'100%'}>
                    <Grid display={'flex'} justifyContent={'center'}>
                      <Stack sx={{ height: 300 }} spacing={1}>
                        <Slider
                          getAriaLabel={() => 'Kilometers driven'}
                          value={kilometersDriven}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={(value) => `${value} km`} // Display values in kilometers
                          sx={{ color: '#FFA800' }}
                          min={0}
                          max={200000}
                          orientation="vertical"
                        />
                      </Stack>
                    </Grid>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
                      <Box sx={{ padding: '4px', color: 'white', backgroundColor: '#202020' }}>
                        {kilometersDriven[0]} km
                      </Box>
                      <Typography color={'white'} px={1}>
                        TO
                      </Typography>
                      <Box sx={{ padding: '4px', color: 'white', backgroundColor: '#202020' }}>
                        {kilometersDriven[1]} km
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item md={8}>
                <Stack direction="row" spacing={1} mb={2}>
                  {/* <FilterDrawer filterData={filterData} /> */}
                  <Grid display={'flex'} className="cat-scroller">
                    <Chip
                      label="All Vehicles"
                      onClick={() => handleChipClick('')}
                      sx={{
                        backgroundColor: category === '' ? '#684500' : '#202020',
                        color: '#E3E3E3',
                        fontWeight: '400px',
                        marginRight: '5px',
                      }}
                    />
                    {categoryList?.map((item, key) => (
                      <Chip
                        label={item?.name}
                        key={key}
                        onClick={() => handleChipClick(item?._id)}
                        sx={{
                          backgroundColor: category === item?._id ? '#684500' : '#202020',
                          color: '#E3E3E3',
                          fontWeight: '400px',
                          marginRight: '5px',
                        }}
                      />
                    ))}
                  </Grid>
                </Stack>
                <SimpleBar style={{ maxHeight: '750px' }}>
                  {allProductsFilterSoldDate()?.length === 0 ? (
                    <Typography display={'flex'} alignItems={'center'} justifyContent={'center'} color={'white'}>
                      No Vehicles
                    </Typography>
                  ) : (
                    <InfiniteScroll
                      dataLength={allProductsFilterSoldDate()?.length ?? 0}
                      next={HandlefetchNextPage}
                      hasMore
                      loader={customLoading}
                      scrollThreshold={0.6}
                      className="infinite-scroll"
                    >
                      <Grid container>
                        {allProductsFilterSoldDate()?.map((vehicle, key) => (
                          <Grid item md={4} lg={4} mb={5} px={1} key={key}>
                            <ProductCard product={vehicle} />
                          </Grid>
                        ))}
                      </Grid>
                    </InfiniteScroll>
                  )}
                </SimpleBar>
              </Grid>
            </Grid>
            <Youtube />
          </>
        )}
      </Box>
    </>
  );
}
