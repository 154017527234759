import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Stack, Grid, TextField, Link } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDevice } from '../hooks/useResponsive';
import ProductCard2 from '../segment/Products/productCard2';
import Youtube from '../segment/@common/Youtube/youtube';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import { getAllCategories, getCategoryDetail, getVehiclesByCategory } from '../server/api/category';
import { createLead } from '../server/api/lead';
import SubmitModal from './popup';

export default function SingleCategory() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const params = useParams();
  const { navigate } = useNavigate();

  const { categoryDetails, vehiclesByCategory, loading, allCategories } = useSelector((state) => ({
    categoryDetails: state.category.categoryDetails?.response,
    allCategories: state.category.categoryList?.response,
    vehiclesByCategory: state.category.vehiclesByCategory,
    loading: state.category.loading,
  }));

  const vehicles = vehiclesByCategory?.vehicles;

  const [modal, setModal] = React.useState(false);
  const [customLoading, setCustomLoading] = useState(false);
  const [allVehicles, setAllVehicles] = useState([]);
  const limitValue = 10;
  const allpages = vehiclesByCategory?.total / limitValue;
  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (pages === 1) {
      setAllVehicles([]);
      setAllVehicles([...(vehicles || [])]);
    } else {
      setAllVehicles([...allVehicles, ...(vehicles || [])]);
    }
  }, [allVehicles, pages, vehicles, vehiclesByCategory]);

  const ids = allVehicles?.map((o) => o._id);

  const filteredFinalProd = allVehicles?.filter(({ _id }, index) => !ids.includes(_id, index + 1));

  useEffect(() => {
    if (loading === true) {
      setCustomLoading(true);
    }
    if (loading === false) {
      setTimeout(() => {
        setCustomLoading(false);
      }, 1500);
    }
  }, [loading]);

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1);
    }
  };

  useEffect(() => {
    const credentials = {
      categoryId: params?.id,
      dispatch,
    };
    dispatch(getCategoryDetail(credentials));
  }, [dispatch, params?.id]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getAllCategories(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      catId: params?.id,
      page: '1',
      search: '',
      limit: 10,
      dispatch,
    };
    dispatch(getVehiclesByCategory(credentials));
  }, [dispatch, params?.id]);

  const CustomButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '23px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      pincode: '',
      message: '',
    },
  });

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        leadType: 'dream_car',
        name: value?.name,
        phone: value?.phone,
        address: {
          pincode: parseInt(value?.pincode, 10),
        },
        message: value?.message,
        medium: 'website',
      },
      dispatch,
      reset,
      navigate,
    };
    dispatch(createLead(credentials));
  };

  return (
    <>
      <MetaHelmet title={'Category'} companyName={'Focus Motors'} />
      <SubmitModal open={modal} close={() => setModal(false)} />
      <Box className="bg-color">
        <Container maxWidth="lg" sx={{ padding: '0px 5px 0px 10px' }}>
          <Typography color={'#F8971C'} fontSize={20} fontWeight={700} textAlign={'center'} pt={2}>
            {categoryDetails?.name}
          </Typography>
          <Typography color={'#CFCFCF'} fontSize={14} fontWeight={200} textAlign={'center'} pt={1}>
            {categoryDetails?.description}
          </Typography>
          <Stack my={3}>
            <img
              src={
                isMobile
                  ? categoryDetails && categoryDetails?.appBanners && categoryDetails?.appBanners[0]?.url
                  : categoryDetails && categoryDetails?.webBanners && categoryDetails?.webBanners[0]?.url
              }
              alt=""
            />
          </Stack>
          <InfiniteScroll
            dataLength={filteredFinalProd?.length ?? 0}
            next={HandlefetchNextPage}
            hasMore
            loader={customLoading}
            scrollThreshold={0.6}
            className="infinite-scroll"
          >
            <Grid container direction={'row'} spacing={0} m={0}>
              {filteredFinalProd?.map((vehicle, key) => (
                <Grid key={key} item xs={12} mb={1} lg={6} md={6}>
                  <ProductCard2 vehicle={vehicle} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
          <Typography color={'#F8971C'} fontSize={15} fontWeight={700} pl={1} pb={2}>
            Other Categories
          </Typography>
          <Grid container direction={'row'} spacing={1} m={0} display={isMobile ? '' : 'f;e'}>
            {allCategories
              ?.filter((item) => item._id !== params?.id)
              ?.map((item, key) => (
                <Grid key={key} item xs={6} mb={1} md={6} lg={6}>
                  <Link href={`/category-single/${item?._id}`}>
                    <img src={isMobile ? item?.appBanners[0]?.url : item?.webBanners[0]?.url} alt="" />
                  </Link>
                </Grid>
              ))}
          </Grid>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Box my={5} px={1}>
              <Typography color={'#F8971C'} fontSize={20} fontWeight={700} textAlign={'center'} pb={4}>
                Didn't Find your Car?
              </Typography>
              <Grid container>
                <Grid item md={4} lg={4} xs={12}>
                  <TextField
                    name="name"
                    fullWidth
                    placeholder="Enter your name"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    {...register('name', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid item md={4} lg={4} xs={12} px={isMobile ? '' : 1}>
                  <TextField
                    name="phone"
                    fullWidth
                    placeholder="Enter your phone"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('phone', {
                      required: { value: false, message: '' },
                      maxLength: {
                        value: 15,
                        message: 'Phone must not exceed 15 characters',
                      },
                      minLength: {
                        value: 9,
                        message: 'Phone must have at least 9 characters',
                      },
                    })}
                  />
                </Grid>
                <Grid item md={4} lg={4} xs={12}>
                  <TextField
                    name="pincode"
                    fullWidth
                    placeholder="Enter your pincode"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('pincode', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid item md={12} lg={12} xs={12}>
                  <TextField
                    name="message"
                    fullWidth
                    placeholder="Tell Us about your Dream Car!"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    rows={3}
                    multiline
                    {...register('message', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
              </Grid>
              <CustomButton type="submit" variant="contained" className="request-btn" fullWidth>
                SUBMIT TO GET NOTIFIED
              </CustomButton>
            </Box>
          </form>
          <Youtube />
        </Container>
      </Box>
    </>
  );
}
