import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, Box, Stack, Grid, CardMedia, Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HeadsetMicTwoToneIcon from '@mui/icons-material/HeadsetMicTwoTone';
import LoadingScreen from '../components/loading/loading-screen';
import { useDevice } from '../hooks/useResponsive';
import Youtube from '../segment/@common/Youtube/youtube';
import ProductCard from '../segment/Products/productCard';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import { getRelatedVehicles, getVehicleDetails } from '../server/api/vehicle';
// import SubmitModal from './popup';
import Lead from './lead';
import useCloudinary from '../hooks/useCloudinaryImage';

export default function ProductSinglePage() {
  const dispatch = useDispatch();
  const params = useParams();
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  const { loading, vehicleDetails, relatedVehicles } = useSelector((state) => ({
    vehicleDetails: state.vehicle.vehicleDetails,
    relatedVehicles: state.vehicle.relatedVehicles,
    loading: state.vehicle.loading,
  }));

  console.log(loading);

  const [scrollValue, setScrollValue] = useState();
  // const [modal, setModal] = React.useState(false);
  const [openLeadModal, setOpenLeadModal] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollValue = window.scrollY;
      setScrollValue(scrollValue);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const credentials = {
      vehicleId: params?.id,
      dispatch,
    };
    dispatch(getVehicleDetails(credentials));
  }, [dispatch, params?.id]);

  useEffect(() => {
    const credentials = {
      vehicleId: params?.id,
      dispatch,
    };
    dispatch(getRelatedVehicles(credentials));
  }, [dispatch, params?.id]);

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 2 : 4.5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  const carouselSettings2 = {
    height: 550,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1.15 : 1.5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  const CustomButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontWeight: 'bold',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const handleOwnershipStatus = (status) => {
    switch (status) {
      case 'first_owner':
        return 'First Owner';
      case 'second_owner':
        return 'Second Owner';
      case 'third_owner':
        return 'Third Owner';
      default:
        return '';
    }
  };

  const capitalizeFirstLetter = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

  // const handleSubmitLead = () => {
  //   const credentials = {
  //     state: {
  //       leadType: 'buy',
  //       vehicle: vehicleDetails?._id,
  //     },
  //     dispatch,
  //     setModal,
  //   };
  //   dispatch(createLead(credentials));
  // };

  console.log(isMobile);
  const image = [{ img: '/assets/car.png' }, { img: '/assets/car.png' }];

  const close = () => {
    setOpenLeadModal(false);
  };

  console.log(relatedVehicles);

  return (
    <>
      <MetaHelmet title={'Product'} companyName={'Focus Motors'} />
      <Lead open={openLeadModal} close={close} setOpenLeadModal={setOpenLeadModal} />
      {/* <SubmitModal open={modal} close={() => setModal(false)} /> */}
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box className="bg-color">
          <Container maxWidth="lg" sx={{ padding: '0px 18px 0px 18px' }}>
            {/* <Slider {...carouselSettings2}>
            {vehicleDetails?.images?.length === 0 ? (
              <>
                {image?.map((item, key) => (
                  <Card className="CardTransform" key={key}>
                    <CardMedia component="img" className="card-img samesize" src={item?.img} alt="Card Image" />
                  </Card>
                ))}
              </>
            ) : (
              <>
                {vehicleDetails?.images?.map((item, key) => (

                  <Card className="CardTransform" key={key}>
                    <CardMedia
                      component="img"
                      className="card-img samesize"
                      src={item?.url }
                      alt="Card Image"
                    />
                  </Card>
                ))}
              </>
            )}
          </Slider> */}
            <Slider {...carouselSettings2}>
              {vehicleDetails?.images?.length === 0 ? (
                <Stack key="default-image" className="related-prod">
                  <Card className="CardTransform">
                    <CardMedia
                      component="img"
                      className="card-img samesize"
                      src="/assets/car.png"
                      alt="Default Image"
                    />
                  </Card>
                </Stack>
              ) : (
                vehicleDetails.images?.map((item, key) => (
                  <Stack key={key} className={!isMobile && 'related-prod'} px={0.3}>
                    <Card className="CardTransform">
                      <CardMedia
                        component="img"
                        className={isMobile ? '' : 'card-img samesize'}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        src={item?.url ? useCloudinary(item.url, 'w_800') : '/assets/car.png'}
                        alt="Card Image"
                        sx={{ objectFit: 'cover' }}
                      />
                    </Card>
                  </Stack>
                ))
              )}
            </Slider>

            <Typography color={'#FFA800'} fontSize={25} fontWeight={300} px={2} pt={3}>
              {vehicleDetails?.name}
            </Typography>
            <Typography color={'white'} fontSize={18} fontWeight={800} px={2}>
              {vehicleDetails?.variant?.name}
            </Typography>
            <Grid container direction="row" spacing={1} mt={2}>
              <Grid item xs={4} md={3} lg={3} mb={1}>
                <Card
                  sx={{
                    backgroundColor: '#202020',
                    boxShadow: 'none',
                    minHeight: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    {vehicleDetails && vehicleDetails?.brand && vehicleDetails?.brand?.logo && (
                      <img src={vehicleDetails?.brand?.logo[0]?.url} alt="" width={'38px'} height={'30px'} />
                    )}
                    <Typography fontSize={12} fontWeight={400} color={'white'} pt={1}>
                      {vehicleDetails?.brand?.name}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={8} md={3} lg={3} mb={1}>
                <Card
                  sx={{
                    backgroundColor: '#202020',
                    boxShadow: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100px',
                  }}
                >
                  <Box>
                    <Typography fontSize={12} fontWeight={400} color={'#FFA800'} pt={1} textAlign={'center'}>
                      Ownership
                    </Typography>
                    <Typography fontSize={20} fontWeight={400} color={'white'} pt={1}>
                      {handleOwnershipStatus(vehicleDetails?.ownershipStatus)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              {isMobile && (
                <Grid item xs={12} md={6} lg={6} mb={1}>
                  <Card
                    sx={{
                      backgroundColor: '#202020',
                      boxShadow: 'none',
                      minHeight: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Stack>
                      <Box display={'flex'} alignItems={'center'}>
                        <img src="/assets/images/Home/km.png" style={{ width: '39px', height: '34px' }} alt="" />
                        <Stack ml={2}>
                          <Typography fontSize={12} fontWeight={400} color={'#FFA800'}>
                            Kilometers Driven
                          </Typography>
                          <Typography fontSize={18} fontWeight={400} color={'#E3E3E3'}>
                            {vehicleDetails?.kiloMeter} Kms
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              )}
              {!isMobile && (
                <Grid item xs={6} mb={1}>
                  <Card
                    sx={{
                      backgroundColor: '#202020',
                      boxShadow: 'none',
                      minHeight: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Stack>
                      <Box display={'flex'} alignItems={'center'}>
                        <img src="/assets/images/Home/km.png" style={{ width: '39px', height: '34px' }} alt="" />
                        <Stack ml={2}>
                          <Typography fontSize={12} fontWeight={400} color={'#FFA800'}>
                            Kilometers Driven
                          </Typography>
                          <Typography fontSize={18} fontWeight={400} color={'#E3E3E3'}>
                            {vehicleDetails?.kiloMeter} Kms
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              )}
              <Grid item xs={7} md={3} lg={3} mb={1}>
                <Card
                  sx={{
                    backgroundColor: '#202020',
                    boxShadow: 'none',
                    display: 'flex',
                    minHeight: '100px',
                  }}
                >
                  <Grid
                    sx={{ backgroundColor: '#292929', width: '40%', minHeight: '100px' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Stack>
                      <img src="/assets/images/Home/fuel.png" alt="" />
                    </Stack>
                  </Grid>
                  <Grid
                    sx={{ width: '60%', minHeight: '100px' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Typography fontSize={18} fontWeight={400} color={'#E3E3E3'}>
                      {capitalizeFirstLetter(vehicleDetails?.fuelType)}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={5} md={2} lg={2} mb={1}>
                <Card
                  sx={{
                    backgroundColor: '#202020',
                    boxShadow: 'none',
                    minHeight: '100px',
                  }}
                >
                  <Grid sx={{ backgroundColor: '#292929', width: '100%' }}>
                    <Typography color={'#FFA800'} fontSize={10} fontWeight={400} textAlign={'center'} py={1}>
                      Purchase Year
                    </Typography>
                  </Grid>
                  <Grid sx={{ width: '100%' }}>
                    <Typography color={'#E3E3E3'} fontSize={30} fontWeight={200} textAlign={'center'} py={1}>
                      {vehicleDetails?.makeYear}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} md={7} lg={7} mb={3}>
                <Card
                  className="location-box-web"
                  sx={{
                    backgroundColor: '#202020',
                    boxShadow: 'none',
                    minHeight: isMobile ? 150 : 100,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    sx={{ width: '50%', minHeight: isMobile ? 150 : '120px', maxHeight: isMobile ? '' : 50 }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Stack>
                      <Typography fontSize={12} fontWeight={400} color={'#FFA800'}>
                        Showroom
                      </Typography>
                      <Typography fontSize={20} fontWeight={400} color={'white'}>
                        Pandikkad
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid sx={{ width: '50%', minHeight: '150px' }}>
                    <img src="/assets/images/Home/map.png" style={{ width: '100%' }} alt="" />
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            {vehicleDetails?.tags?.length > 0 && (
              <Typography color={'#F8971C'} fontSize={15} fontWeight={700}>
                More Features
              </Typography>
            )}
            <Grid container direction="row" mb={3}>
              {vehicleDetails?.tags?.map((item, key) => (
                <Grid item xs={6} md={3} lg={3} display={'flex'} alignItems={'center'} mt={3} key={key}>
                  <Stack position={'relative'}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#202020',
                        width: '25px',
                        height: '25px',
                        boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.7)',
                      }}
                    />
                    <DoneIcon
                      style={{ color: '#FFA800', position: 'absolute', top: '2px', left: '2px' }}
                      fontSize="small"
                    />
                  </Stack>
                  <Typography color={'white'} pl={1}>
                    {item}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {vehicleDetails?.description && (
              <Stack className={`${!isMobile && ''}`} mb={!isMobile && 5}>
                <Typography color={'#F8971C'} fontSize={15} fontWeight={700}>
                  Discription
                </Typography>
                <Typography fontSize={12} color={'white'} fontWeight={300} pt={1}>
                  {vehicleDetails?.description}
                </Typography>
              </Stack>
            )}
            <div className="">
              <div className="w-100">
                {!isMobile && (
                  <Grid display="flex" alignItems="center" justifyContent="center" py-2>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      className=""
                      onClick={() => setOpenLeadModal(true)}
                      sx={{
                        flexDirection: 'column',
                        px: 25,
                        display: 'flex',
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        {' '}
                        <HeadsetMicTwoToneIcon sx={{ fontSize: 20 }} /> &nbsp;CLICK FOR MORE DETAILS
                      </Stack>
                      <small style={{ fontSize: 10, display: 'flex' }} className="">
                        <span className="">Our car guide will call you soon.</span>
                      </small>
                    </CustomButton>
                  </Grid>
                )}
              </div>
            </div>

            <Typography color={'#F8971C'} fontSize={15} fontWeight={700} pt={5} pb={2}>
              Related Cars
            </Typography>
            <Slider {...carouselSettings}>
              {relatedVehicles?.relatedVehicles?.map((related, key) => (
                <Stack key={key} className="related-prod">
                  <ProductCard product={related} />
                </Stack>
              ))}
            </Slider>

            {isMobile && (
              <Grid
                display={'flex'}
                my={5}
                position={'fixed'}
                bottom={60}
                zIndex={1}
                width={'90%'}
                className={`floating-card ${scrollValue >= 290 ? 'active' : ''}`}
              >
                <Box
                  width={'25%'}
                  mr={1}
                  sx={
                    {
                      // borderRadius: '10px',
                      // aspectRatio: 4 / 3,
                      // height: '65px',
                    }
                  }
                >
                  {vehicleDetails && vehicleDetails?.images && (
                    <img
                      src={
                        vehicleDetails?.images[0]?.url
                          ? // eslint-disable-next-line react-hooks/rules-of-hooks
                            useCloudinary(vehicleDetails?.images[0]?.url, 'w_500')
                          : image[0]?.img
                      }
                      alt=""
                      style={{
                        width: '100%',
                        objectFit: 'cover',
                        height: '',
                        borderRadius: '10px',
                        aspectRatio: 4 / 3,
                        border: '#f8971c 1.5px solid',
                      }}
                    />
                  )}
                </Box>
                <CustomButton
                  type="submit"
                  variant="contained"
                  className="request-btn"
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    px: 2,
                  }}
                  onClick={() => setOpenLeadModal(true)}
                >
                  <span style={{ fontSize: 18, fontWeight: 900 }}> Get Price & Test Drive!</span>
                  <span style={{ fontSize: 11 }}> Our car guide will give you a call</span>
                </CustomButton>
              </Grid>
            )}
            <Stack mt={2}>
              <Youtube />
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
}
